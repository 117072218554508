<template>

  <div>
      <section class="bets-menu d-flex mt-1 mb-1">

        <div id="jp1" class=" p-1 px-2 active"  @click="getJackpot(1)">
          Daily Jackpot 
        </div>

        <div id="jp2" class=" p-1 px-2 text-blue"  @click="getJackpot(2)">
          Weekly Jackpot 
        </div>

        <div id="jp3" class=" p-1 px-2 text-blue"  @click="getJackpot(3)">
          Super 6 Jackpot
        </div>

      </section>

      <section v-show="matches.length > 0" class="jackpot-section-header px-2 py-2">
        <div class="jackpot-header-wrapper p-0 text-center">

          <div class="orange-text text-center">
            {{ jackpot.name }}
          </div>

          <div class="text-light jp-anc">
            <strong>WIN KSH. {{ formatCurrency(jackpot.prize) }} </strong>
          </div>

          <div class="text-light small-text">
            Expires on {{ formatMatchDate(jackpot.end) }}
          </div>

          <div class="orange-text mb-1">
            KSH. {{ formatCurrency(jackpot.stake) }}/= TU! <span class="text-light">{{ jackpot.games }} GAMES PEKEE</span>
          </div>

         

        </div>
      </section>

      <div v-if="loading">
        <ShimmerJackpot></ShimmerJackpot>
      </div>
      <div v-else >
        <JackpotGames
            v-for="(m,index) in matches"
            v-bind:key="getLeagueKey(index)"
            v-bind:current_row="index"
            v-bind:fixture="m"></JackpotGames>
      </div>
      <div class="text-center text-light" v-if="matches.length == 0">
        Jackpot is coming soon!
      </div>

      <BottomNavigation v-show="matches.length > 0" v-bind:games="jackpot.games" v-bind:stake="jackpot.stake" v-bind:jackpot_category="jackpot_category"></BottomNavigation>

  </div>

</template>

<script>
  import axios from "@/services/api";
  import JackpotGames from "./JackpotGames";
  import BottomNavigation from "./BottomNavigation";
  import ShimmerJackpot from '../shimmer/ShimmerJackpot.vue'
  export default {
    name: 'Jackpot',
    components: {
      JackpotGames,
      BottomNavigation,
      ShimmerJackpot
    },
    data: function () {
      return {
        end: '',
        countDown: '',
        matches: [],
        details: [],
        results: [],
        prev: [],
        sub_page: '',
        jackpot_category: 1,
        key: '1',
        isActive: false,
        activeClass: 'active',
        view_more: false,
        loading: true,
        btn: '',
        jackpot: {},
        loadingMessage: "",
        retries: 0

      }
    },
    methods: {
      makeActive: function(item) {
        // When a model is changed, the view will be automatically updated.
        this.active = item;
      },
      autoPick: function () {

      this.picked_button = 'auto';

      this.clearJackpotBetSlip();
      var max = 10;
      var r = 0;
      while (r < max ) {

        // generate random between 0 and 2
        var i = this.getRandomInt(3)
        var id = 'r'+r+'c'+i;
        //console.log('Wants to click '+id);
        //this.jQuery("#"+id).click();
        this.jQuery("[unique="+id+"]").click();
        r++
      }
    },
      getLeagueKey: function(index){

        return Math.random().toString(10).replace('0.', 'competition-id-'+index + '-');

      },

      getOutcomeKey: function(index){

        return Math.random().toString(10).replace('0.', 'outcome-'+index + '-');

      },

      getJackpot: function (category_id) {

        var vm = this;
        this.jackpot_category = category_id;
        vm.matches = [];
        var path = process.env.VUE_APP_URL_JP+"/"+category_id;
        this.loadingMessage = "Loading Jackpot Games...";
        document.getElementById("jp1").classList.remove("active");
        document.getElementById("jp2").classList.remove("active");
        document.getElementById("jp3").classList.remove("active");
        document.getElementById("jp"+category_id).classList.add("active");
        axios.post(path, JSON.stringify({}))
        .then(res => {

          var games = res.data.message.data;
          vm.matches = games;
          vm.jackpot = res.data.message.jackpot;
          vm.jackpot.games = games.length;
          vm.autoRefreshJackpotUI(vm.$vnode.tag);
          console.log("games length " + games.length)
          if(parseInt(games.length) != 0){
            this.loadingMessage = "Jackpot coming soon!"
          }else{
            this.loadingMessage = "";
          }
          this.loading = false;
          console.log("loading is: " + this.loading);
          

        }).catch(err => {
          console.log(err);
            this.loading = false;
            this.loadingMessage = "An error occured";
          
        })

      },

      getColor: function(home, away){
        if (home > away){
          return "btn btn-success";
        }else if(home < away){
          return "btn btn-danger"
        }else {
          return "btn"
        }
      },

      getJackpotResult: function () {

        var vm = this;
        var path = process.env.VUE_APP_URL_JP_RESULT.replace("{count}", 5);

        axios.post(path, JSON.stringify({}))
        .then(res => {
          var games = res.data.message;
          vm.results = games;
        })

      },

      getMonth: function (i){

        var month = new Array();
        month[0] = "Jan";
        month[1] = "Feb";
        month[2] = "Mar";
        month[3] = "Apr";
        month[4] = "May";
        month[5] = "Jun";
        month[6] = "Jul";
        month[7] = "Aug";
        month[8] = "Sep";
        month[9] = "Oct";
        month[10] = "Nov";
        month[11] = "Dec";

        return month[i]
      },

      addZero: function (i) {
        if (i < 10) {
          i = "0" + i;
        }
        return i;
      },

      formatDate: function (date) {

        if (date === false || date === undefined || date === "" || date === null) {

          return ""
        }

        var res = date.replace(" ", "T");
        var d = new Date(res);
        var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
        var mon = months[d.getMonth()];
        var dt = d.getDate();
        var day = dt > 9 ? dt : '0' + dt;
        var hr = d.getHours();
        var hour = hr > 9 ? hr : '0' + hr;
        var min = d.getMinutes();
        var minutes = min > 9 ? min : '0' + min;
        var formated = day + '/' + mon + ', ' + hour + ':' + minutes;
        return formated;
      },

      viewResult: function(prev) {

        this.view_more = true;

        this.prev = prev;

      },
      getHomeCompetitorName: function (matchName) {

        if (matchName === undefined) {

          return "";
        }

        return matchName.split('vs.')[0];
      },
      getAwayCompetitorName: function (matchName) {

        if (matchName === undefined) {

          return "";
        }
        return matchName.split('vs.')[1];
      },
    },
    mounted() {

      this.$store.dispatch("setCurrentPage", "jackpot");
      this.getJackpot(1);
      this.getJackpotResult();

      var vm = this;

      setTimeout(function () {
        vm.autoRefreshJackpotUI("setInterval");
      },2000);

    },
    computed: {
      current_sub_page: function () {

        return this.$store.state.current_sub_page;
      },
      current_page: function () {

        return this.$store.state.current_page;
      }
    }
  }
</script>


