<template>

  <div>

    

    <div class="spacer">

    </div>

    <section class="footer-betslip-section-jackpot">
      <div class="row m-0 p-0 py-1 mb-2 autopick-stripe">

      <div class="col-3 p-0 m-0 px-1 small-btn-padding text-center slip-col px-1">
        <a href="#" class="login-button py-1 form-control small-text" @click="autoPick">Autopick</a>
      </div>

      <div class="col-3 p-0 m-0 px-1 small-btn-padding text-center slip-col px-1">
        <a href="" class="login-button py-1 d-none form-control small-text">Share</a>
      </div>

      <div class="col-4 p-0 m-0 px-0 small-btn-padding text-center text-yellow slip-col px-1">
        Picked: <span class="text-light"><span class="jp-counter" id="jp-counter">{{ betslip_count }}</span>  out of {{ games }}</span>
      </div>

      <div class="col-2 p-0 m-0 px-0 small-btn-padding text-center slip-col px-1">
        <a href="#" class="text-blue small-text clear-btn" @click="clearJP">Clear [x]</a>
      </div>

    </div>

      <div class="row  px-2 m-0 pb-2">

        <div class="col-3 slip-slip-slip px-0 pt-1">
            <span class="slip-counter jp-counter" style="padding:5px 6px">
                {{ betslip_count }}
            </span>
          <span class="slip-text">
                Betslip
            </span>
        </div>

        <div class="col-3 px-0">
          <div class="jp-slip-odds pt-1 d-none">
            Stake: <strong id="stake">{{ stake }}</strong>
          </div>
        </div>

        <div class="col-6 px-0 ">
          <div class="slip-btn-wrapper">
            <button class="slip-btn shadow " @click="placeJPBet">Place Bet</button>
          </div>
        </div>
      </div>
    </section>

  </div>

</template>

<script>

import axios from "@/services/api";

export default {
  name: "bottom-navigation",
  props: {
    games: {
      required: true
    },
    stake: {
      required: true
    },
    jackpot_category: {
      required: true
    }
  },
  data: function () {
    return {
      loading: '',
      bestlip_visible: false,
      code: '',
      msg: '',
      share_odds: '',
      copyText: 'Copy',
      copyBookingCode: 'Click to copy',
      myProfile: this.getProfile(),
      accept_odds_changes: true,
      previous_odds: 0,
      odds_changed: false,
      seen: false,
      withholding_tax: 0,
      booking_code: '',
      picked_button: '',
    }
  },
  methods: {
    clearJP: function (){

      this.picked_button = 'clear';
      this.clearJackpotBetSlip();

    },
    isPicked: function(share){

      return share === this.picked_button ? 'jp-selected-btn' : 'jp-btn';

    },
    loadBookingCode: function(){

      if(this.booking_code.trim().length > 4 ) {

        if(this.currentRouteName !== "share") {

          this.$router.push({name: 'share', params: {code: this.booking_code.trim()}});

        } else {

          this.EventBus.$emit('share:code', this.booking_code.trim());
        }

      } else {

        this.setError("invalid code","Please enter a valid booking code to proceed");

      }

    },
    shareBetMessage: function(){

      //var code = this.code;
      var odds = this.share_odds;
      var toWin = this.formatCurrency(parseFloat(odds) * 200);
      this.msg = this.msg.replace('{possible_win}',toWin);

    },
    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }

      return parseFloat(x).toFixed(2);
    },
    getSportAlt: function (sport_id) {

      switch (parseInt(sport_id)) {

        case 1:
          return "Soccer Icon";

        case 2:
          return "basketball icon";

        case 4:
          return "hockey icon";

        case 5:
          return "tennis icon";

        case 22:
          return "/assets/icons/dart.svg";

        case 20:
          return "/assets/icons/tabletennis.svg";

        case 6:
          return "/assets/icons/handball.svg";

        case 12:
          return "/assets/icons/rugby.svg";

        case 10:
          return "/assets/icons/boxing.svg";

        case 31:
          return "/assets/icons/badminton.svg";

        case 3:
          return "/assets/icons/baseball.svg";
      }

      return ""
    },
    show: function () {

      this.jQuery('#betslip-modal').modal('show');
      //var modal = document.getElementById("betslip-modal");
      //modal.style.display = "block";
      this.bestlip_visible = true;
    },
    placeJPBet: function () {

      this.picked_button = 'bet';
      this.reset();
      var p = this.getProfile();

      if(!p) {

        this.setError("Login","Please login to proceed");
        this.setValue("placeBet",3);
        this.$router.push({ name: 'login', params: { } });
        return;
      }

      var bet_amount = this.getValue("stake");
      var booking_code = this.getValue("booking_code");
      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      if (bet_amount < 1) {

        this.setError("Invalid Bet Amount", "Please enter bet amount greater of 1Ksh or more ");
        return;
      }

      var betslipData = this.betslip;

      if (betslipData.total === 0) {

        this.setError("Invalid Bets", "Please Select atleast one outcome to continue");
        return;
      }

      if (!this.accept_odds_changes) {

        this.setError("Odds Changed", "Accept Odds Changes to Proceed");
        return;

      }

      var bets = [];

      this.jQuery.each(betslipData.picks, function (k, v) {

        bets.push({
          odd_id: v.odd_id
        });

      });

      var data = {
        stake: bet_amount,
        bets: bets,
        code: booking_code,
        jackpot_category: this.jackpot_category,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer,
        source: this.isMobile() ? 2 : 1
      };


      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_JP_BET.replace("{profile_id}", p.d);

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var profile = res.data.message.profile;
            var message = res.data.message.message;
            vm.setProfile(profile);
            vm.clearJackpotBetSlip();
            vm.setSuccess("Success", message);
            vm.removeObject('booking_code');

          })
          .catch(err => {

            // console.log(JSON.stringify(err,undefined,4));

            vm.loading = '';
            if (err.response) {

              var message = "";

              if(parseInt(err.response.status) === 428 ) {

                message = err.response.data.message.message;
                var amount = err.response.data.message.amount;

                vm.EventBus.$emit('deposit:popup',{
                  amount: amount,
                  message:message,
                });

              }

              if(parseInt(err.response.status) === 422 ) {

                message = err.response.data.message;
                vm.setError("Failed", message)
                return
              }

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                message =  err.response.data.message;
                vm.setError("Failed", message)
              }

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },
    oddStatus: function () {

      var picks = this.betslip.picks;

      if (picks === undefined || picks.length === 0) {

        return;

      }

      var bets = [];

      this.jQuery.each(picks, function (k, v) {

        bets.push(v.odd_id+":"+v.producer_id);

      });

      var data = {
        odd_id: bets.join(',')
      };

      if (bets.length === 0) {

        return;

      }

      var vm = this;

      var path = process.env.VUE_APP_URL_ODD_STATUS;

      axios.post(path, JSON.stringify(data))
          .then(res => {

            var bets = res.data.message;

            vm.jQuery.each(bets,function(k,v){

              var oddID = v.id;
              var odds = v.odds;
              var previous_odds = v.previous_odds;
              var status = v.status;
              var active = v.active;
              var producer_status = v.producer_status;

              // get original odds
              // check odds changes
              vm.jQuery.each(picks,function(k1,v1){

                if(parseInt(v1.odd_id) === parseInt(oddID) ) {

                  var oddChangeText = "";
                  odds = vm.formatOdds(odds);

                  if(odds !== vm.formatOdds(v1.odd)) {

                    // odds changed
                    if(odds > v1.odd ) {

                      var direction = 1

                    } else {

                      direction = 0

                    }

                    var directionTxt = parseInt(direction) === 1 ? 'odds has gone up ' : 'odds has gone down ';
                    oddChangeText = directionTxt + ' from '+v1.odd+' to '+odds;

                  }

                  v1.status = status;
                  v1.active = active;
                  v1.odds = odds;
                  v1.odd = odds;
                  v1.previous_odds = previous_odds;
                  v1.producer_status = producer_status;
                  v1.odds_change_text = oddChangeText;
                  picks[k1] = v1;
                }
              })

            });

            var pk = [];

            vm.jQuery.each(picks,function(k1,v1){

                pk.push(v1);
            })

            vm.saveObject("jslip", pk);
            vm.autoRefreshJackpotUI(vm.$vnode.tag);

          })
          .catch(err => {

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            } else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }
          })
    },
    getOddStatus: function(odd) {

      if(odd.active === 0  || odd.producer_status === 0 ) {

        return '<div class="deactivated-odds">Outcome suspended</div>';

      }

      if(parseInt(odd.status) === 0 || parseInt(odd.status) === -1 ) {

        return '<div class="deactivated-odds">Outcome Deactivated</div>';

      }

      if(odd.odds_change_text && odd.odds_change_text.length > 0 ) {

        return '<div class="odds-change" style="">'+odd.odds_change_text+'</div>';

      }

    },
    showBetslip: function() {

      document.getElementById("betslip-init").click();

    },
    autoPick: function () {

      this.picked_button = 'auto';

      this.clearJackpotBetSlip();
      
      var max = this.games;
      var r = 0;
      while (r < max ) {

        // generate random between 0 and 2
        var i = this.getRandomInt(3)
        var id = 'r'+r+'c'+i;
        //console.log('Wants to click '+id);
        //this.jQuery("#"+id).click();
        this.jQuery("[unique="+id+"]").click();
        r++
      }
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    bal: function() {

      return this.formatCurrency(this.profile.b1);

    },

    iconSize: function (){

      return 28;
    },
    profile: function () {

      return this.myProfile;

    },
    liveGames: function (){

      return this.$store.state.live_match_counter

    },
    homePageIcon: function () {

      if (this.page === 'home') {

        return this.getURL("/assets/images/home_icon_selected.svg");

      }

      return this.getURL("/assets/images/home_icon.svg");

    },
    homePageFontColor: function () {

      if (this.page === 'home') {

        return "yellow-txt";
      }

      return "";

    },
    livePageFontColor: function () {

      if (this.page === 'live') {

        return "yellow-txt";
      }

      return "";

    },
    historyPageFontColor: function () {

      if (this.page === 'history') {

        return "yellow-txt";
      }

      return "";

    },
    betslip_count: function () {

      if(!this.betslip.total || this.betslip.total == "") {

        return 0
      }

      return parseInt(this.betslip.total);

    },
    activeBets: function () {

      var p = this.getProfile();
      if(!p) {

        return 0;

      }

      return p.b

    },
    odds: function () {

      return this.betslip.odds
      //return this.$store.state.betslip.odds

    },
    payout: function () {

      return this.betslip.payout

    },
    betslip: function () {

      //var bSlip = this.getObject("betslip");
      //return bSlip;
      return this.$store.state.jackpotbetslip
    },
    has_suspended_picks: function (){

      return this.$store.state.has_suspended_jackpot_picks

    },
    isLoggedIn: function (){

      var p = this.getProfile();
      if(!p) {
        return false;
      }

      return true;
    },

  },
  filters: {

    currency: function (amount) {

      if (amount == null) {

        amount = 0;
      }

      return (amount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'KES',
      });

    },
    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }

      return parseFloat(x).toFixed(2);
    }
  },
  watch: {

    stake: function (newValue) {

      // update stake
      this.setValue("stake", newValue);

      // recalculate winnings
      this.autoRefreshJackpotUI(this.$vnode.tag);

    }
  },
  components: {
  },
  mounted: function () {

    // reset stake
    this.setValue("stake",this.stake);
    this.previous_odds = this.betslip.odds;

    this.$store.dispatch("getLiveMatchCounter");
    var vm = this;

    this.EventBus.$on('event:jackpotbetslip:placeBet',function(){

      vm.placeBet(true);

    });

    this.EventBus.$on('profile:balance',function(payload){

      vm.myProfile = payload;

    });

    this.EventBus.$on('jackpotodds:changed',function(payload){

      vm.odds_changed = true;
      vm.updateJackpotOdd(payload.id,payload.odds,payload.previous_odds,payload.active,payload.status);

    });

  }
}
</script>
